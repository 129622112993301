import React, { useEffect, useState } from 'react';
import ReportTimePeriodPermission from '../components/report.period.analysis.permission';
import { useParams } from "react-router-dom";
import permissionService from '../services/permissionService';

//version of the permission by get id.
// const ReportWorkingHour = () => {
//     const params = useParams();
//     const [allowGroupId, setAllowGroupId] = useState(null);

//     useEffect(() => {
//         const fetchPermission = async () => {
//             let allowGroupId = await checkGroupIdPermission(params.id);
//             setAllowGroupId(allowGroupId);
//         };
//         fetchPermission();
//     }, [params.id]);
//     if (allowGroupId != null) {
//         return (
//             <ReportTimePeriodPermission inputAllowWorkGroupId={allowGroupId} />
//         );
//     } else {
//         return <h1>No Permission to access this report</h1>;
//     }
// }

// const checkGroupIdPermission = async (pId) => {
//     try{
//             const response = await permissionService.getGroupIdPermission({permission_id: pId});
//             if(response.data.code === "000"){
//                 return response.data.data;
//             }else{
//                 return null;
//             }
//     }catch(error){
//             console.error('Error fetching group ID permission:', error);
//             return null;
//     }
    
// };

//2024-08-29 wait delete this function.
// const checkSmartTrackTokenPermission = async (pId) => {
//     try{
//             const response = await permissionService.getVerifySmartTrackToken({smarttracktoken: pId});
//             if(response.data.code === "000"){
//                 return response.data.data;
//             }else{
//                 return null;
//             }
//     }catch(error){
//             console.error('Error fetching group ID permission:', error);
//             return null;
//     }
    
// };

const ReportWorkingHour = () => {
    const params = useParams();
    const [allowGroupId, setAllowGroupId] = useState(null);
    const [reason , setReason] = useState('');
    useEffect(() => {
        const fetchPermission = async () => {
            // console.log('show url params',params.id);
            let resAllow = await checkTokenPermission(params.id);
            console.log(resAllow);
            if(!!resAllow && resAllow.code === '099'){
                setReason(resAllow.message);
                setAllowGroupId(null);
            }else if(!!resAllow && resAllow.code === '000'){
                setAllowGroupId(resAllow.data); //Pass 
            }else{
                setAllowGroupId(null);
            }
        };
        fetchPermission();
    }, [params.id]);
    if (allowGroupId != null) {
        return (
            <ReportTimePeriodPermission inputAllowWorkGroupId={allowGroupId} />
        );
    } else {
        return <h1>No Permission to access this report01: {reason}</h1>;
    }
}
//accept with id is token from FE of start point.
const checkTokenPermission = async (token) => {
    try{
            const response = await permissionService.getWorkGroupReportFromSmartTrackApi(token);
            if(response === null){
                return null;
            }
            if(response.data.code === '000'){
                return response.data;
            }else if(response.data.code === '099'){
                return response.data;
            }else{
                return null;
            }
    }catch(error){
            console.error('Error checkTokenPermission', error.code);
            return {code: "099", message: error.message};
    }
    
};



export default ReportWorkingHour;