import React, { useState, useEffect } from 'react';
import { Button, Select,Table } from 'antd';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import GetStatusType from "../utils/work.status";
// import constantsYearLoad from '../constants/month.period.year.load';
// import constants from '../constants/day.period.month';
import constantsH from "../constants/hour.period.day";
import constantsM from '../constants/month.period.year';

import dayjs from 'dayjs'
import 'dayjs/locale/th';
dayjs.locale("th");

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const { Option } = Select;
// const { Search } = Input;
const columnsTable = [
  {
    title: 'ช่วงเวลา',
    dataIndex: 'periods',
    key: 'periods',
  },
  {
    title: 'สถานะ',
    dataIndex: 'status_type',
    key: 'status_type',
  },
  {
    title: 'นาที',
    dataIndex: 'minute_amt',
    key: 'minute_amt',
  }
];

const HourOfDay = ({                 objDownload
                                      ,indexObj
                                      ,selectedMonth
                                      ,selectedDayOfMonth
                                      ,selectedVehicleId
                                      ,handleMonthOfYear
}) => {
  const [showWorkGroupName, setShowWorkGroupName] = useState('Loading');
  
  const [vehicles, setVehicles] = useState([]);
  const [selectVehicleIdOption, setSelectVehicleIdOption] = useState(null);
  
  const [dataChartWorking, setDataChartWorking] = useState([]);
  const [dataChartBreak, setDataChartBreak] = useState([]);

  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
                    const downloadWorkGroupData = async (year,month,day,resApi,indexObj,vehicleId) => {
                          let objArray = await getData(year,month,day,resApi[indexObj]);
                          let arrayVehicleHourWorking = [];
                          let arrayVehicleHourBreak = [];
                          let runKey = 0;
                          objArray.forEach( obj  => {
                            obj.key = runKey++;
                            if(obj.status_type === 'ชั่วโมงการทำงาน'){
                              arrayVehicleHourWorking.push(obj.minute_amt);
                            }else if(obj.status_type === 'ชั่วโมงพักงาน'){
                              arrayVehicleHourBreak.push(obj.minute_amt);
                            }
                          });

                          setDataChartWorking(arrayVehicleHourWorking);
                          setDataChartBreak(arrayVehicleHourBreak);
                          await setDataTable(objArray);
                          return;
                        // setShowProgress(false);
                    };

                    const getData = async (ofYear,ofMonth,ofDay,resVehicle) => {
                        try {
                          // let resFiltered = [];
                          // let index = 0;
                          // for (let el of resVehicle) {
                          //     if( dayjs(el.sttime).isBefore(ofDay) && dayjs(el.etime).isAfter(ofDay)){
                          //       console.log('show index',index ,'show sttime',dayjs(el.sttime).format('YYYY-MM-DD HH:mm:ss'),'show etime',dayjs(el.etime).format('YYYY-MM-DD HH:mm:ss'));
                          //       console.log('show ofDay', ofDay.format('YYYY-MM-DD HH:mm:ss'))
                                
                          //       resFiltered.push(el);
                          //     }
                          //     index++;
                          // }
                          // console.log('show resFiltered',resFiltered);

                          const objArray = await calculateHours2(ofYear,ofMonth,ofDay,resVehicle);
                          // const objArray = await calculateHours2(ofYear,ofMonth,ofDay,resFiltered);
                          
                          // Clear format to display chart
                          objArray.forEach((obj) => {
                            obj.hour_amt = parseFloat(obj.hour_amt).toFixed(2);
                            obj.defaultValue = false;
                            obj.vehicleId = resVehicle.vehicleid;
                          });
                          return objArray;
                        } catch (err) {
                          console.error(err); // Use console.error for more prominent error logging
                          throw err; // Re-throw the error to propagate it to the caller
                        }
                    }
                  
                    const calculateHours2 = (ofYear,ofMonth,ofDay,data) => {
                        let allPeriodData = [];
                  
                        if (data?.length > 0) {
                          allPeriodData = calculateEachRow2(ofYear,ofMonth,ofDay,data);
                          return allPeriodData;
                        } else {
                          return allPeriodData;
                        }
                    } 
                  
                    const calculateEachRow2 = (ofYear,ofMonth,ofDay,d) => {
                          let arrayPeriodDataWorking = [];
                          let arrayPeriodDataBreak = [];
                          let arrayPeriodDataUndefine = [];
                          
                          arrayPeriodDataWorking = structuredClone(constantsH.defaultResultWorking);
                          arrayPeriodDataBreak = structuredClone(constantsH.defaultResultBreak);
                          arrayPeriodDataUndefine = structuredClone(constantsH.defaultResultUndefine);
                          

                          //initHourUndefine(ofYear,arrayPeriodDataUndefine,arrayPeriodDataWorking,arrayPeriodDataBreak);
                      
                            d?.forEach(row => {
                               
                                let strStartTime = '';
                                strStartTime = row.sttime;
                                let strEndTime = '';
                                strEndTime = row.etime;
                                let dayjsStart = dayjs(strStartTime);
                                let dayjsEnd = dayjs(strEndTime);
                             
                                let dayjsStartingPoint = dayjs(strStartTime.slice(0,13)+':00'); //Move to point start hour.
                                let dayjsEndingPoint = dayjs(strEndTime.slice(0,13)+':00').add(1,'hour'); //Move to point end hour.
                                                                
                                const sizeOfRange = dayjsEndingPoint.diff(dayjsStartingPoint,'minute');
                              
                                const qtySlot = sizeOfRange/60; //is qty hour of all.
                                // console.log('show qtySlot',qtySlot, 'round of size',sizeOfRange);

                                let minuteDiff = 0;
                                // let j = 0;
                                for(let i = 0; i < qtySlot; i++ ){
                                    //print slot 1 is 
                                    let djsSlotTime = dayjsStartingPoint.add(i,'hour');
                                    // console.log(`1.Show ofDay`,ofDay.format('YYYY-MM-DD') );
                                    // console.log(`Show djDay`,djsSlotTime.format('YYYY-MM-DD') );

                                    //filter with of_day parameter
                                    if( ofDay.format('YYYY-MM-DD') === djsSlotTime.format('YYYY-MM-DD')){ //check date
                                        // console.log('show slot time ',i, ' of ',djsSlotTime.format('YYYY-MM-DD HH:mm:ss'), ' is ',j++);
                                        if(i === 0){ //First slot
                                          if(i !== qtySlot-1){
                                            minuteDiff = dayjsStart.diff(dayjsStartingPoint.add(1,'hour'),'minute') * -1; //Multiply -1 for convert to time used.
                                          }else{
                                            minuteDiff = dayjsStart.diff(dayjsEnd,'minute') * -1 ;
                                          }
                                        }else if(i === qtySlot-1){ //Last slot
                                          minuteDiff = dayjsEnd.diff(dayjsEndingPoint.add(-60,'minute'),'minute'); //Multiply -1 for convert to time used.
                                        }else{ //full hour slot
                                          minuteDiff = 60;
                                        }
                                        // console.log('show minuteDiff ',j, ' diff ',minuteDiff);
                                        // console.log('show Slot loop ',dayjsStart.format('HH:mm'),dayjsStart.add(minuteDiff,'minute').format('HH:mm'),' @', i);
                                        // console.log('show minuteDiff',minuteDiff);
                                        let strDatetimeOfPointHour = dayjsStart.add(i,'hour').startOf('hour').format('YYYY-MM-DD HH:00');
                                        let iPointHour = dayjsStart.add(i,'hour').hour();

                                        // console.log(`show iPointHour ${iPointHour} of ${ofYear} / ${ofMonth} / ${ofDay.format('YYYY-MM-DD')}`);
                                        pushDataIntoSlotHours(ofYear,ofMonth,ofDay,strDatetimeOfPointHour,iPointHour,row, i,minuteDiff,arrayPeriodDataWorking,arrayPeriodDataBreak,arrayPeriodDataUndefine);
                                    }
                                }
                            });
                            // return arrayPeriodDataWorking.concat(arrayPeriodDataUndefine,arrayPeriodDataBreak); //marge array
                            return arrayPeriodDataWorking.concat(arrayPeriodDataBreak); //marge array
                    };

                    const pushDataIntoSlotHours = (ofYear,ofMonth,ofDay,strDateTimeOfPointHour,iPointHour,row, indexSlot,minute_amt,arrayPeriodDataWorking,arrayPeriodDataBreak,arrayPeriodDataUndefine) => {
                          //Validate data before operation.    
                          if(ofDay.format('YYYY-MM-DD') !== dayjs(strDateTimeOfPointHour).format('YYYY-MM-DD')) //diff day.
                          {
                            return;
                          }
                          /*
                          //Concept push by date
                          //loop arrayPeriodDataWorking
                          //loop arrayPeriodDataBreak
                          //loop arrayPeriodDataUndefine
                          */
                          // console.log('show arrayPeriodDataWorking',arrayPeriodDataWorking);
                          // console.log('show arrayPeriodDataBreak',arrayPeriodDataBreak);
                          // let iDateOfMonth = dayjs(strDateTimeOfPointHour).date();
                          // iDateOfMonth = iDateOfMonth-1; //convert to index
                          
                            if(GetStatusType(row.statusname) === 'ชั่วโมงการทำงาน'){
                                if(!arrayPeriodDataWorking || !arrayPeriodDataUndefine){ //validate array before for undefined error.
                                  return;
                                }
                                //Use index for push data hour_amt
                                arrayPeriodDataWorking[iPointHour].minute_amt = arrayPeriodDataWorking[iPointHour].minute_amt + (minute_amt); //;
                                //and remain slot of undefined in same time.
                                arrayPeriodDataUndefine[iPointHour].minute_amt = arrayPeriodDataUndefine[iPointHour].minute_amt - (minute_amt); //;
                              
                            }else if(GetStatusType(row.statusname) === 'ชั่วโมงพักงาน'){
                                if(!arrayPeriodDataBreak || !arrayPeriodDataUndefine){
                                  return;
                                }

                                //Use index for push data hour_amt
                                arrayPeriodDataBreak[iPointHour].minute_amt = arrayPeriodDataBreak[iPointHour].minute_amt + (minute_amt); //;
                                //and remain slot of undefined in same time.
                                arrayPeriodDataUndefine[iPointHour].minute_amt = arrayPeriodDataUndefine[iPointHour].minute_amt - (minute_amt); //;
                            }
                            else{ //Undifined
                                if(!arrayPeriodDataUndefine){
                                  return;
                                }
                                arrayPeriodDataBreak[iPointHour].minute_amt = arrayPeriodDataBreak[iPointHour].minute_amt + (minute_amt); //;
                                //and remain slot of undefined in same time.
                                arrayPeriodDataUndefine[iPointHour].minute_amt = arrayPeriodDataUndefine[iPointHour].minute_amt - (minute_amt); //;
                            } 
                            // console.log('show hour',iPointHour);
                            // console.log('show after arrayWorking',arrayPeriodDataWorking[iPointHour])
                            // console.log('show after arrayBreak',arrayPeriodDataBreak[iPointHour])
                            // console.log('show after arrayWorking',arrayPeriodDataUndefine[iPointHour])
                    };
                    
            if(!!objDownload && (!!indexObj || indexObj === 0) && !!selectedVehicleId){
                // console.log("show selectedDayOfMonth:",selectedDayOfMonth)
                setShowWorkGroupName(objDownload.vehiclesArray[indexObj].vehiclename+'@'+objDownload.year+'-'+selectedMonth+'-'+selectedDayOfMonth);
                setVehicles(objDownload.vehiclesArray);
                
                if(!!selectedMonth && !!selectedDayOfMonth){
                  const monthIndex = constantsM.months.indexOf(selectedMonth);
                  const searchDate = dayjs([objDownload.year,monthIndex+1,parseInt(selectedDayOfMonth.slice(1))]);
                  
                  downloadWorkGroupData(objDownload.year,selectedMonth,searchDate,objDownload.responseApiOfWorkgroup,indexObj,selectedVehicleId);
                }
            }
  }, [objDownload,indexObj,selectedVehicleId,selectedMonth,selectedDayOfMonth]);

  const handleItemClicked = (label,value,index) => {
    console.log('show vechicle array',vehicles[index]);
  }
  
  const ListVehicle = () => (
      <div style={{ marginTop: '1%', height: 50 }}>
          <Select
            showSearch={true}
            style={{ width: '200px', marginRight: 10 }}
            placeholder="Select vehicle"
            value={selectVehicleIdOption}
            onChange={(key,value) => {
              setSelectVehicleIdOption(key);
            }}
          >
            {vehicles?.map(x => (
              <Option key={x.vehicleid} value={x.vehicleid}>
                {x.vehiclename}
              </Option>
            ))}
          </Select>
          <Button
            type="primary" 
            onClick={(event) => {
                console.log('show selectVehicleIdOption',selectVehicleIdOption);
            }}
          >
            View
          </Button>
    </div>
  )

  // The following colors will be used sequentially for the chart bars
  const options = {
    plugins: {
      title: {
          position: 'top',
          align: 'center',
          display: true,
          text: 'กราฟอัตราชั่วโมงการทำงานรายชั่วโมงของรถ: '+showWorkGroupName,//+selectedWorkGroupName,
          padding: {
              top: 1,
              bottom: 0
          },
          font: {
            size: 20
          }
      },
      legend: {
        display: true,
        // labels: {
        //   color: 'rgb(255, 255, 132)'
        // }
      },
      tooltip: {
        callbacks: {
          label: function (data) {
            return data.formattedValue + ' Minute';
          },
        },
      },
      datalabels: {
        formatter: function (value) {
          if(parseInt(value) === 0){
            return '';
          }
          return parseFloat(value).toFixed(0);
        },
        color: "white",
        font: {
          weight: 'bold',
          size: 12,
          family: 'poppins'
        },
      }
    },
    scales: {
      x: {
          stacked: true,
          grid: {
            offset: true,
          },
          // title: {
          //   color: 'red',
          //   display: true,
          //   text: 'Day'
          // }
      },
      y: {
          stacked: true,
          // min: 0,
          // max: 20,
          title: {
            color: 'Blue',
            display: true,
            text: 'Minute'
          },
          ticks: {
            // stepSize: 5,
            callback: function(value, index, ticks) {
              return value;
            }
          }
      }
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const { datasetIndex, index } = elements[0];
        const dataset = data.datasets[datasetIndex];
        const value = dataset.data[index];
        const label = data.labels[index];
        // console.log(`Label: ${label}, Value: ${value}`);
        handleItemClicked(label,value,index);
      }
    }
  };
  const data = {
    labels: constantsH.slotHours,
    datasets: [
      {
        label: 'ชั่วโมงการทำงาน',
        data: dataChartWorking,
        backgroundColor: 'green',
        borderColor: 'black', 
        borderWidth: 1,
        hoverBorderColor: 'grey',
        hoverBorderWidth: 2.5
      },
      {
        label: 'ชั่วโมงหยุดพัก',
        data: dataChartBreak,
        backgroundColor: 'red',
        borderColor: 'black', 
        borderWidth: 1,
        hoverBorderColor: 'grey',
        hoverBorderWidth: 2.5
      },
    ],
  };

  return (
    <div>
          
      <div
        style = {
          { padding: '15px',marginRight: '40px'}
        } 
      >
        <Bar
          data={data} 
          options={options} >
        </Bar>
        <div>
              { false ? <ListVehicle /> : null }
              {/* { !!vehicles ? <ListVehicle /> : null } */}
        </div>
      </div>
      
      <div style={{ height: '400px' }}>
          <Table columns={columnsTable} dataSource={dataTable} />
      </div>
    </div>
  );
}

export default HourOfDay;