import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom'; 
import ReportWorkingHour from './pages/reportWorkingHour';

function App() {
  return (
    <Routes>
        {/* <Route path='/' element={<ReportWorkingHour />}/> */}
        <Route path='/report01/:id' element={<ReportWorkingHour />}/>
    </Routes>
  );
}

export default App;