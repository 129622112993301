import React, { useState, useEffect } from 'react';
import { Button, Select,Tabs } from 'antd';


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import GetStatusType from "../utils/work.status";
import constantsYearLoad from '../constants/month.period.year.load';

import dayjs from 'dayjs'
import 'dayjs/locale/th';
dayjs.locale("th");

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const { Option } = Select;
// const { Search } = Input;

//BF Come back to create dynamically data structures from dataChartWorking and dataChartBreak ( qty_vehicle / 28) = tabs to show
// const chartData =
// [
//   { id: 1, labelsX: `1`, ds: '' },
//   { id: 2, labelsX: `2`, ds: '' },
//   { id: 3, labelsX: `3`, ds: '' },
//   { id: 4, labelsX: `4`, ds: '' },
//   { id: 5, labelsX: `5`, ds: '' },
//   { id: 6, labelsX: `6`, ds: '' }
// ];


const YearOfWorkgroupTab = ({         objDownload
                                      ,handleChartYear
}) => {
  // const focusWorkGroupId = selectedWorkGroupId;
  // const [dataFetched, setDataFetched] = useState('idle');
  const [showWorkGroupName, setShowWorkGroupName] = useState('Loading');
  // const [showYear, setShowYear] = useState('Loading');
  // const [responseApiOfWorkgroup, setResponseApiOfWorkgroup] = useState([]);
  
  const [vehicles, setVehicles] = useState([]);
  const [selectVehicleIdOption, setSelectVehicleIdOption] = useState(null);
  
  // const [arrayVehicleName, setArrayVehicleName] = useState([]);
  // const [dataChart, setDataChart] = useState([]);
  // const [dataChartWorking, setDataChartWorking] = useState([]);
  // const [dataChartBreak, setDataChartBreak] = useState([]);
  const [chartData, setChartData] = useState([{ id: 0, labelsX: `0`, data_labels: [] }]);
  const [chartLabelVehicle, setChartLabelVehicle] = useState([]);
  const [chartDataWorking, setChartDataWorking] = useState([]);
  const [chartDataBreak, setChartDataBreak] = useState([]);

  const sizeShowTab = 10;
  useEffect(() => {
                    const downloadWorkGroupData = async (year,resApi) => {
                        
                        let arrayVehicleHourWorking = [];
                        let arrayVehicleHourBreak = [];

                        for await (let resVehicle of resApi){ 
                            // console.log('show each vehicle',resVehicle);
                            if(!!resVehicle){
                              let objArray = await getData(year,resVehicle);

                              let totalWorkingHour = 0;
                              let totalBreakHour = 0;
                              // let totalUndefineHour = 8760; //365 day of year.
                              objArray.forEach( obj  => {
                                // console.log('show objArray ', obj);
                                if(obj.status_type === 'ชั่วโมงการทำงาน'){
                                  totalWorkingHour += parseFloat(obj.hour_amt);
                                  // totalUndefineHour -= totalWorkingHour;
                                }else if(obj.status_type === 'ชั่วโมงพักงาน'){
                                  totalBreakHour += parseFloat(obj.hour_amt);
                                  // totalUndefineHour -= totalBreakHour;
                                }
                              });
                              // setPercentProgress(parseInt((index/vehicleTotal)*100));
                              arrayVehicleHourWorking.push(parseFloat(totalWorkingHour).toFixed(2));
                              arrayVehicleHourBreak.push(parseFloat(totalBreakHour).toFixed(2));
                            }
                        }
                        
                        let splittedArrayWorking = arrayVehicleHourWorking.reduce((result, item, index) => {
                          if (index % sizeShowTab === 0) {
                              result.push(arrayVehicleHourWorking.slice(index, index + sizeShowTab));
                          }
                          return result;
                        }, []);
                        setChartDataWorking(splittedArrayWorking);

                        let splittedArrayBreak = arrayVehicleHourBreak.reduce((result, item, index) => {
                          if (index % sizeShowTab === 0) {
                              result.push(arrayVehicleHourBreak.slice(index, index + sizeShowTab));
                          }
                          return result;
                        }, []);
                        setChartDataBreak(splittedArrayBreak);
                        
                        // setDataChartWorking(arrayVehicleHourWorking);
                        // setDataChartBreak(arrayVehicleHourBreak);
                        
                        // setShowProgress(false);
                    };

                    const getData = async (ofYear,resVehicle) => {
                        try {
                          
                          const objArray = calculateHours2(ofYear,resVehicle);
                          
                          // Clear format to display chart
                          objArray.forEach((obj) => {
                            obj.hour_amt = parseFloat(obj.hour_amt).toFixed(2);
                            obj.defaultValue = false;
                            obj.vehicleId = resVehicle.vehicleid;
                          });
                      
                          return objArray;
                        } catch (err) {
                          console.error(err); // Use console.error for more prominent error logging
                          throw err; // Re-throw the error to propagate it to the caller
                        }
                    }
                  
                    const calculateHours2 = (ofYear,data) => {
                        let allPeriodData = [];
                  
                        if (data?.length > 0) {
                          allPeriodData = calculateEachRow2(ofYear,data);
                          return allPeriodData;
                        } else {
                          return allPeriodData;
                        }
                    } 
                  
                    const calculateEachRow2 = (ofYear,d) => {
                          let arrayPeriodDataWorking = [];
                          let arrayPeriodDataBreak = [];
                          let arrayPeriodDataUndefine = [];
                          
                          arrayPeriodDataWorking = structuredClone(constantsYearLoad.defaultResultWorking);
                          arrayPeriodDataBreak = structuredClone(constantsYearLoad.defaultResultBreak);
                          arrayPeriodDataUndefine = structuredClone(constantsYearLoad.defaultResultUndefine);
                  
                          initHourUndefine(ofYear,arrayPeriodDataUndefine,arrayPeriodDataWorking,arrayPeriodDataBreak);
                      
                            d?.forEach(row => {
                                
                                let strStartTime = '';
                                strStartTime = row.sttime;
                                let strEndTime = '';
                                strEndTime = row.etime;
                                // console.log('show strStartTime:', strStartTime);
                                // console.log('show strEndTime:', strEndTime);
                                let dayjsStart = dayjs(strStartTime);
                                let dayjsEnd = dayjs(strEndTime);
                                let dayjsStartingPoint = dayjs(strStartTime.slice(0,13)+':00'); //Move to point start hour.
                                let dayjsEndingPoint = dayjs(strEndTime.slice(0,13)+':00').add(1,'hour'); //Move to point end hour.
                                
                                // console.log('show dayjsStart:', dayjsStart.format('YYYY-MM-DD HH:mm'));
                                // console.log('show dayjsEnd:', dayjsEnd.format('YYYY-MM-DD HH:mm'));
                                // console.log('show time total==',dayjsStart.diff(dayjsEnd,'minute') * -1 );
                                // console.log('show dayjsStartingPoint:', dayjsStartingPoint.format('YYYY-MM-DD HH:mm'));
                                // console.log('show dayjsEndingPoint:', dayjsEndingPoint.format('YYYY-MM-DD HH:mm'));
                      
                                const sizeOfRange = dayjsEndingPoint.diff(dayjsStartingPoint,'minute');
                              
                                const qtySlot = sizeOfRange/60; //each slot is 60 minutes.
                                // console.log('show qtySlot',qtySlot);
                                
                                let minuteDiff = 0;
                                for(let i = 0; i < qtySlot; i++ ){
                                    if(i === 0){ //First slot
                                      if(i !== qtySlot-1){
                                        // console.log('Ranges of[', dayjsStart.format('YYYY-MM-DD HH:mm'),' @ ', dayjsStartingPoint.add(1,'hour').format('YYYY-MM-DD HH:mm') ,'@',i);
                                        minuteDiff = dayjsStart.diff(dayjsStartingPoint.add(1,'hour'),'minute') * -1; //Multiply -1 for convert to time used.
                                      }else{
                                        // console.log('Ranges of[', dayjsStart.format('YYYY-MM-DD HH:mm'),' @ ', dayjsEnd.format('YYYY-MM-DD HH:mm') ,'@',i);
                                        minuteDiff = dayjsStart.diff(dayjsEnd,'minute') * -1 ;
                                      }
                                    }else if(i === qtySlot-1){ //Last slot
                                      // console.log('Ranges of[', dayjsEndingPoint.add(-60,'minute').format('YYYY-MM-DD HH:mm'),' @ ', dayjsEnd.format('YYYY-MM-DD HH:mm') ,'@',i);
                                      minuteDiff = dayjsEnd.diff(dayjsEndingPoint.add(-60,'minute'),'minute'); //Multiply -1 for convert to time used.
                                      // console.log('show at last dayjsEnd', dayjsEnd.format('YYYY-MM-DD HH:mm') );
                                      // console.log('show at last dayjsEndingPoint', dayjsEndingPoint.format('YYYY-MM-DD HH:mm') );
                                    }else{ //full hour slot
                                      // console.log('Ranges of[', dayjsStartingPoint.add(60*i,'minute').format('YYYY-MM-DD HH:mm'),' @ ', dayjsStartingPoint.add((60*i)+60,'minute').format('YYYY-MM-DD HH:mm') ,'@',i);
                                      minuteDiff = 60;
                                    }
                                    // console.log('show Slot loop ',dayjsStart.format('HH:mm'),dayjsStart.add(minuteDiff,'minute').format('HH:mm'),' @', i);
                                    // console.log('show minuteDiff',minuteDiff);
                                    let strDatetimeOfPointHour = dayjsStart.add(i,'hour').startOf('hour').format('YYYY-MM-DD HH:00');
                                    let iPointHour = dayjsStart.hour() + i;
                                    pushDataIntoSlotHours(ofYear,strDatetimeOfPointHour,iPointHour,row, i,minuteDiff,arrayPeriodDataWorking,arrayPeriodDataBreak,arrayPeriodDataUndefine);
                                }
                      
                            });
                  
                            // console.log('show view array after working::', arrayPeriodDataWorking);
                            // console.log('show view array after undefine::', arrayPeriodDataUndefine);
                            // console.log('show view array after break::', arrayPeriodDataBreak);
                  
                            // return arrayPeriodDataWorking.concat(arrayPeriodDataUndefine,arrayPeriodDataBreak); //marge array
                            return arrayPeriodDataWorking.concat(arrayPeriodDataBreak); //marge array
                    };
                    
                    const pushDataIntoSlotHours = (ofYear,strDatetimeOfPointHour,iPointHour,row, indexSlot,minute_amt,arrayPeriodDataWorking,arrayPeriodDataBreak,arrayPeriodDataUndefine) => {
                          //Validate data before operation.    
                          if( ofYear !== dayjs(strDatetimeOfPointHour).year() ){
                              return;
                          }
                          /*
                          //Concept push by date
                          //loop arrayPeriodDataWorking
                          //loop arrayPeriodDataBreak
                          //loop arrayPeriodDataUndefine
                          */
                          let iMonthOfYear = dayjs(strDatetimeOfPointHour).month();
                            if(GetStatusType(row.statusname) === 'ชั่วโมงการทำงาน'){
                                if(!arrayPeriodDataWorking || !arrayPeriodDataUndefine){ //validate array before for undefined error.
                                  return;
                                }
                                //Use index for push data hour_amt
                                arrayPeriodDataWorking[iMonthOfYear].hour_amt = arrayPeriodDataWorking[iMonthOfYear].hour_amt + (minute_amt/60.00); //;
                                //and remain slot of undefined in same time.
                                arrayPeriodDataUndefine[iMonthOfYear].hour_amt = arrayPeriodDataUndefine[iMonthOfYear].hour_amt - (minute_amt/60.00); //;
                              
                            }else if(GetStatusType(row.statusname) === 'ชั่วโมงพักงาน'){
                                if(!arrayPeriodDataBreak || !arrayPeriodDataUndefine){
                                  return;
                                }
                                //Use index for push data hour_amt
                                arrayPeriodDataBreak[iMonthOfYear].hour_amt = arrayPeriodDataBreak[iMonthOfYear].hour_amt + (minute_amt/60.00); //;
                                //and remain slot of undefined in same time.
                                arrayPeriodDataUndefine[iMonthOfYear].hour_amt = arrayPeriodDataUndefine[iMonthOfYear].hour_amt - (minute_amt/60.00); //;
                            }
                            else{ //Undifined
                                if(!arrayPeriodDataUndefine){
                                  return;
                                }
                            } 
                    };
                  
                    const initHourUndefine = (ofYear,arrayPeriodDataUndefine,arrayPeriodDataWorking,arrayPeriodDataBreak) => {
                      arrayPeriodDataUndefine.forEach( e => {
                          e.hour_amt = 800;
                          e.year = ofYear;
                          e.vehicleId = null;
                      });
                      arrayPeriodDataWorking.forEach( e => {
                        e.hour_amt = 0;
                        e.year = ofYear;
                        e.vehicleId = null;
                      });
                      arrayPeriodDataBreak.forEach( e => {
                        e.hour_amt = 0;
                        e.year = ofYear;
                        e.vehicleId = null;
                      });
                    };
                    
            if(!!objDownload){
                setShowWorkGroupName(objDownload.workGroupName+'@'+objDownload.year);
                // setResponseApiOfWorkgroup(objDownload.responseApiOfWorkgroup);
                setVehicles(objDownload.vehiclesArray);
                let listVehicleLicense = [];
                // let qty_tab = chartData.length;
                // let index = 1;
                // let bufferTabChart = chartData;
                // objDownload.vehiclesArray.forEach( row =>{
                //   listVehicleLicense.push(row.vehiclelicence);
                //   if( ((index+(chartData.length * sizeShowTab)) % sizeShowTab) == 0){
                //       qty_tab++;
                //       bufferTabChart.push({ id: qty_tab, labelsX: ``+qty_tab, ds: '' })
                //   }
                //   index++;
                // });
                // console.log("Show chartData", chartData);
                let qty_tab = 0;
                let index = 1;
                let bufferTabChart = [];
                let lengthVehiclesArray = objDownload.vehiclesArray.length;
                objDownload.vehiclesArray.forEach( row =>{
                  listVehicleLicense.push(row.vehiclelicence);
                  
                  if( ((index) % sizeShowTab) === 0){
                      bufferTabChart.push({ id: qty_tab, labelsX: ``+qty_tab, data_labels: [] })
                      qty_tab++;
                  }else if(index === (lengthVehiclesArray-1)){
                      bufferTabChart.push({ id: qty_tab, labelsX: ``+qty_tab, data_labels: [] })
                      qty_tab++;
                  }
                  index++;
                });
                setChartData(bufferTabChart);

                let splittedArrayVehicle = listVehicleLicense.reduce((result, item, index) => {
                  if (index % sizeShowTab === 0) {
                      result.push(listVehicleLicense.slice(index, index + sizeShowTab));
                  }
                  return result;
                }, []);
                setChartLabelVehicle(splittedArrayVehicle);
                
                // setArrayVehicleName(listVehicleLicense);
                downloadWorkGroupData(objDownload.year,objDownload.responseApiOfWorkgroup);
            }
  }, [objDownload]);

  const handleItemClicked = (label,value,index) => {
    // console.log('show label: '+label,': '+ index + ' V: '+ vehicles[index].vehicleid);
    handleChartYear(index,vehicles[index]);

    // console.log('show vechicle array',vehicles[index]);
    // let elementClick = {
    //   year: selectedYear, 
    //   vehicleId: vehicles[index].vehicleid ,
    //   vehicleName: vehicles[index].vehiclename,
    //   workGroupId: focusWorkGroupId,
    //   workGroupName: selectedWorkGroupName,
    //   totalWorkingHour: value
    // };
    // handleWorkGroupAndYear(elementClick,vehicles);
  }
  
  const ListVehicle = () => (
      <div style={{ marginTop: '1%', height: 50 }}>
          <Select
            showSearch={true}
            style={{ width: '200px', marginRight: 10 }}
            placeholder="Select vehicle"
            value={selectVehicleIdOption}
            onChange={(key,value) => {
              console.log('show key and value',key, value);
              setSelectVehicleIdOption(key);
            }}
          >
            {vehicles?.map(x => (
              <Option key={x.vehicleid} value={x.vehicleid}>
                {x.vehiclename}
              </Option>
            ))}
          </Select>
          <Button
            type="primary" 
            onClick={(event) => {
                // console.log('show selectVehicleIdOption',selectVehicleIdOption);
            }}
          >
            View
          </Button>
    </div>
  )

  // The following colors will be used sequentially for the chart bars
  // const options = {
  //   plugins: {
  //     title: {
  //         position: 'top',
  //         align: 'center',
  //         display: true,
  //         text: 'กราฟอัตราชั่วโมงการทำงานของกลุ่งงาน: '+showWorkGroupName,//+selectedWorkGroupName,
  //         padding: {
  //             top: 1,
  //             bottom: 0
  //         },
  //         font: {
  //           size: 20
  //         }
  //     },
  //     legend: {
  //       display: true,
  //       // labels: {
  //       //   color: 'rgb(255, 255, 132)'
  //       // }
  //     },
  //     tooltip: {
  //       callbacks: {
  //         label: function (d) {
  //           return d.formattedValue + ' Hour';
  //         },
  //       },
  //     },
  //     datalabels: {
  //       formatter: function (value) {
  //         if(parseInt(value) === 0){
  //           return '';
  //         }
  //         return parseFloat(value).toFixed(0);
  //       },
  //       color: "white",
  //       font: {
  //         weight: 'bold',
  //         size: 12,
  //         family: 'poppins'
  //       },
  //     }
  //   },
  //   scales: {
  //     x: {
  //         stacked: true,
  //         grid: {
  //           offset: true,
  //         },
  //         // title: {
  //         //   color: 'red',
  //         //   display: true,
  //         //   text: 'Day'
  //         // }
  //     },
  //     y: {
  //         stacked: true,
  //         // min: 0,
  //         // max: 20,
  //         title: {
  //           color: 'Blue',
  //           display: true,
  //           text: 'Hour'
  //         },
  //         ticks: {
  //           // stepSize: 5,
  //           callback: function(value, index, ticks) {
  //             return value;
  //           }
  //         }
  //     }
  //   },
  //   onClick: (event, elements) => {
  //     if (elements.length > 0) {
  //       const { datasetIndex, index } = elements[0];
  //       const dataset = data.datasets[datasetIndex];
  //       const value = dataset.data[index];
  //       const label = data.labels[index];
        
  //       console.log(`Label: ${label}, Value: ${value}, Index: ${index}`);//BF Comme back here...
  //       handleItemClicked(label,value,index);
  //     }
  //   }
  // };
  
    // const data = {
    //   labels: arrayVehicleName,
    //   datasets: [
    //     {
    //       label: 'ชั่วโมงการทำงาน',
    //       data: dataChartWorking,
    //       backgroundColor: 'green',
    //       borderColor: 'black', 
    //       borderWidth: 1,
    //       hoverBorderColor: 'grey',
    //       hoverBorderWidth: 2.5
    //     },
    //     {
    //       label: 'ชั่วโมงหยุดพัก',
    //       data: dataChartBreak,
    //       backgroundColor: 'red',
    //       borderColor: 'black', 
    //       borderWidth: 1,
    //       hoverBorderColor: 'grey',
    //       hoverBorderWidth: 2.5
    //     },
    //   ],
    // };

    return (
              <div>
                    <div
                      style = {
                        { padding: '15px',marginRight: '40px'}
                      } 
                    >
                            <Tabs
                                tabPosition={'left'}
                                items={chartData.map(row => {
                                  const tabId = row.id;
                                  const d = {
                                                labels: chartLabelVehicle[tabId],
                                                datasets: [
                                                  {
                                                    label: 'ชั่วโมงการทำงาน',
                                                    data: chartDataWorking[tabId],
                                                    backgroundColor: 'green',
                                                    borderColor: 'black', 
                                                    borderWidth: 1,
                                                    hoverBorderColor: 'grey',
                                                    hoverBorderWidth: 2.5
                                                  },
                                                  {
                                                    label: 'ชั่วโมงหยุดพัก',
                                                    data: chartDataBreak[tabId],
                                                    backgroundColor: 'red',
                                                    borderColor: 'black', 
                                                    borderWidth: 1,
                                                    hoverBorderColor: 'grey',
                                                    hoverBorderWidth: 2.5
                                                  },
                                                ],
                                            };
                                  const op = {
                                                plugins: {
                                                  title: {
                                                      position: 'top',
                                                      align: 'center',
                                                      display: true,
                                                      text: 'กราฟอัตราชั่วโมงการทำงานของกลุ่งงาน: '+showWorkGroupName,//+selectedWorkGroupName,
                                                      padding: {
                                                          top: 1,
                                                          bottom: 0
                                                      },
                                                      font: {
                                                        size: 20
                                                      }
                                                  },
                                                  legend: {
                                                    display: true,
                                                    // labels: {
                                                    //   color: 'rgb(255, 255, 132)'
                                                    // }
                                                  },
                                                  tooltip: {
                                                    callbacks: {
                                                      label: function (d) {
                                                        return d.formattedValue + ' Hour';
                                                      },
                                                    },
                                                  },
                                                  datalabels: {
                                                    formatter: function (value) {
                                                      if(parseInt(value) === 0){
                                                        return '';
                                                      }
                                                      return parseFloat(value).toFixed(0);
                                                    },
                                                    color: "white",
                                                    font: {
                                                      weight: 'bold',
                                                      size: 12,
                                                      family: 'poppins'
                                                    },
                                                  }
                                                },
                                                scales: {
                                                  x: {
                                                      stacked: true,
                                                      grid: {
                                                        offset: true,
                                                      },
                                                      // title: {
                                                      //   color: 'red',
                                                      //   display: true,
                                                      //   text: 'Day'
                                                      // }
                                                  },
                                                  y: {
                                                      stacked: true,
                                                      // min: 0,
                                                      // max: 20,
                                                      title: {
                                                        color: 'Blue',
                                                        display: true,
                                                        text: 'Hour'
                                                      },
                                                      ticks: {
                                                        // stepSize: 5,
                                                        callback: function(value, index, ticks) {
                                                          return value;
                                                        }
                                                      }
                                                  }
                                                },
                                                onClick: (event, elements) => {
                                                  if (elements.length > 0) {
                                                    const { datasetIndex, index } = elements[0];
                                                    const dataset = d.datasets[datasetIndex];
                                                    const value = dataset.data[index];
                                                    const label = d.labels[index];
                                                    
                                                    // console.log(`Label: ${label}, Value: ${value}, Index: ${index}, TabId: ${tabId}`);
                                                    handleItemClicked(label,value,(index+(tabId*sizeShowTab)));
                                                  }
                                                }
                                  };
                                  return {
                                            label: `Tab-${tabId+1}`,
                                            key: tabId,
                                            children: <Bar
                                                        data={d} 
                                                        options={op} 
                                                        key={`bar-${tabId}`}>
                                                      </Bar>,
                                          };
                                })}
                            />
                            {/* <Tabs defaultActiveKey="1">
                                      {chartData.map((chart, index) => (
                                          <TabPane tab={`Chart ${chart.id}`} key={chart.id}>
                                                    <Bar
                                                      data={data} 
                                                      options={options} >
                                                    </Bar>
                                          </TabPane>
                                      ))}
                            </Tabs> */}
                            {/* <Bar
                              data={data} 
                              options={options} >
                            </Bar> */}
                            <div>
                                  { false ? <ListVehicle /> : null }
                                  {/* { !!vehicles ? <ListVehicle /> : null } */}
                            </div>
                    </div>
              </div>
    );
}

export default YearOfWorkgroupTab;