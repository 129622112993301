const GetStatusType = (startStatus, endStatus = '') => {
    if (startStatus === "W" && endStatus === "W") {
        return "ชั่วโมงการทำงาน";
    } else if (startStatus === "W" && endStatus === "R") {
        return "ชั่วโมงการทำงาน";
    } else if (startStatus === "R" && endStatus === "W") {
        return "ชั่วโมงพักงาน";
    } else if (startStatus === "R" && endStatus === "R") {
        return "ชั่วโมงพักงาน";
    } else if (startStatus === "เดินทาง" && endStatus === "") {
        return "ชั่วโมงการทำงาน";
    } else if ((startStatus === "จอดรถ" || startStatus === "หยุดรถ") && endStatus === "") {
        return "ชั่วโมงพักงาน";
    } else {
        return "ชั่วโมงอับสัญญาณ";
    }
}

export default GetStatusType;
