const slotHours = [
  '00:00-01:00',
  '01:00-02:00',
  '02:00-03:00',
  '03:00-04:00',
  '04:00-05:00',
  '05:00-06:00',
  '06:00-07:00',
  '07:00-08:00',
  '08:00-09:00',
  '09:00-10:00',
  '10:00-11:00',
  '11:00-12:00',
  '12:00-13:00',
  '13:00-14:00',
  '14:00-15:00',
  '15:00-16:00',
  '16:00-17:00',
  '17:00-18:00',
  '18:00-19:00',
  '19:00-20:00',
  '20:00-21:00',
  '21:00-22:00',
  '22:00-23:00',
  '23:00-23:59'
]

const defaultResult = [
  {
      periods: "00:00-01:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "01:00-02:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "02:00-03:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "03:00-04:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "04:00-05:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "05:00-06:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "06:00-07:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "07:00-08:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "08:00-09:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "09:00-10:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "10:00-11:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "11:00-12:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "12:00-13:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "13:00-14:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "14:00-15:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "15:00-16:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "16:00-17:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "17:00-18:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "18:00-19:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "19:00-20:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "20:00-21:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "21:00-22:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "22:00-23:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "23:00-23:59",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#52c41a'
  }
]

const defaultResultBreak = [
  {
      periods: "00:00-01:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "01:00-02:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "02:00-03:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "03:00-04:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "04:00-05:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "05:00-06:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "06:00-07:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "07:00-08:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "08:00-09:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "09:00-10:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "10:00-11:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "11:00-12:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "12:00-13:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "13:00-14:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "14:00-15:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "15:00-16:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "16:00-17:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "17:00-18:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "18:00-19:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "19:00-20:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "20:00-21:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "21:00-22:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "22:00-23:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  },
  {
      periods: "23:00-23:59",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงพักงาน",
      defaultValue: true,
      color: '#f73b3b'
  }
]

const defaultResultWorking = [
  {
      periods: "00:00-01:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "01:00-02:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "02:00-03:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "03:00-04:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "04:00-05:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "05:00-06:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "06:00-07:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "07:00-08:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "08:00-09:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "09:00-10:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "10:00-11:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "11:00-12:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "12:00-13:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "13:00-14:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "14:00-15:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "15:00-16:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "16:00-17:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "17:00-18:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "18:00-19:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "19:00-20:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "20:00-21:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "21:00-22:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "22:00-23:00",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  },
  {
      periods: "23:00-23:59",
      minute_amt: 0,
      status_code: ["", ""],
      status_type: "ชั่วโมงการทำงาน",
      defaultValue: true,
      color: '#52c41a'
  }
]

//Start with 60 minute fill
const defaultResultUndefine = [
  {
      periods: "00:00-01:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "01:00-02:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "02:00-03:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "03:00-04:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "04:00-05:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "05:00-06:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "06:00-07:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "07:00-08:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "08:00-09:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "09:00-10:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "10:00-11:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "11:00-12:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "12:00-13:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "13:00-14:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "14:00-15:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "15:00-16:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "16:00-17:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "17:00-18:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "18:00-19:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "19:00-20:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "20:00-21:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "21:00-22:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "22:00-23:00",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  },
  {
      periods: "23:00-23:59",
      minute_amt: 60,
      status_code: ["", ""],
      status_type: "ชั่วโมงอับสัญญาณ",
      defaultValue: true,
      color: '#a5afc2'
  }
]

const periodsResult = [

]

module.exports = {
  slotHours,
  defaultResult,
  defaultResultBreak,
  defaultResultWorking,
  defaultResultUndefine,
  periodsResult
}