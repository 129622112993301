import React, { useState, useEffect } from 'react';
import { Button, Select, Badge } from 'antd';
import { Flex, Progress } from 'antd';
// import { Input } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';

// import workGroupService from '../services/workGroupService';
import vehicleService from '../services/vehicleService';
import reportService from '../services/reportService';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import dayjs from 'dayjs'
import 'dayjs/locale/th';
dayjs.locale("th");

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const { Option } = Select;
// const { Search } = Input;

const DownloadDataOfWorkgroupPermission = ({allowWorkGroupId,handleResultApiAll}) => {
  const focusWorkGroupId = null;//allowWorkGroupId;//9;
  const [selectWorkGroupName, setSelectWorkGroupName] = useState([]);

  const [focusWorkGroupName, setFocusWorkGroupName] = useState('');
  const [dataFetched, setDataFetched] = useState('idle');
  const [selectionYear, setSelectionYear] = useState([]);
  
  const [vehicles, setVehicles] = useState([]);
  const [vehicleTotal, setVehicleTotal] = useState(0);
  
  // const [workGroup, setWorkGroup] = useState([]);
  // const [workGroupId, setWorkGroupId] = useState(0);
  // const [workGroupFetched, setWorkGroupFetched] = useState(false);

  const [selectedYear, setSelectedYear] = useState();

  const [showProgress, setShowProgress] = useState(false);
  const [percentProgress, setPercentProgress] = useState(0);
  // const [dataChart, setDataChart] = useState([]);
  // const [dataChartWorking, setDataChartWorking] = useState([]);
  // const [dataChartBreak, setDataChartBreak] = useState([]);

  useEffect(() => {
              //   const getWorkGroup = async () => {
              //       const req = {
              //         tbname: "workgroup",
              //         keyword: ""
              //       }
                    
              //       await workGroupService.getWorkGroup(req)
              //       .then((res) => {
              //         if (res.status === 200) {
              //           // setWorkGroup(res.data.data);
              //           //setFocusWorkGroupName for display screen.
              //           for(let e of res.data.data ){
              //             if(e['workgroupid'] === focusWorkGroupId){
              //               setFocusWorkGroupName(e['workgroupname']);
              //               getVehicles(focusWorkGroupId);//get data vehicle of this workgroup id.
              //               break;
              //             }
              //           }
              //           // setWorkGroupFetched(true);
              //         }
              //       })
              //       .catch((err) => {
              //         console.log(err);
              //         // setWorkGroupFetched(false);
              //       });
                      
              //   }
              //   const getVehicles = async (groupId) => {
              //       const req = {
              //         tbname: "vehicle",
              //         workgroupid: groupId,
              //         keyword: ""
              //       }

              //       await vehicleService.getVehicles(req)
              //       .then((res) => {
              //         if (res.status === 200) {
              //           setVehicles(res.data.data);
              //           setVehicleTotal(res.data.data.length);
              //         }
              //       })
              //       .catch((err) => {
              //         console.log(err);
              //       });
              // }

      if (dataFetched === 'idle') {
          setDataFetched('loading');
          setSelectionYear(prepareSelectionYearly());
          
          setSelectWorkGroupName(loadAllowWorkGroup(allowWorkGroupId));
          // getWorkGroup();
          
      }
  }, [dataFetched,focusWorkGroupId,allowWorkGroupId])
  const loadAllowWorkGroup = (arrayData) => {
    let objArray = []; 
    for(let e of arrayData ){
      // console.log('show row',e['id'],':',e['groupname']);
      objArray.push({workgroupid: e['id'], workgroupname: e['groupname'] });
    }
    return objArray;
  }

  const prepareSelectionYearly = () => {
    const currentYear = new Date().getFullYear();
    let objArray = []; 
    for (let i = 0; i < 5; i++) { //Backward 5 years
      objArray.push(currentYear - i);
    }
    return objArray;
  }

  const loadSelectedData = (workGroupId,workGroupName) => {
    setFocusWorkGroupName(workGroupName);
    // console.log('loadSelectedData',workGroupId,':',workGroupName);
    loadVehicles(workGroupId);
  }
  const loadVehicles = async (groupId) => {
    const req = {
      tbname: "vehicle",
      workgroupid: groupId,
      keyword: ""
    }

    await vehicleService.getVehicles(req)
    .then((res) => {
      if (res.status === 200) {
        setVehicles(res.data.data);
        setVehicleTotal(res.data.data.length);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
  
  const handleStart = async () => {
      let resArray = await downloadWorkGroupData();
      //handle selected search
      let elementHandle = {
        workGroupId: focusWorkGroupId,
        workGroupName: focusWorkGroupName,
        year: selectedYear,
        vehicleTotal: vehicleTotal,
        vehiclesArray: vehicles,
        responseApiOfWorkgroup: resArray,
      };
      handleResultApiAll(elementHandle);
  }
  // const handleGetDataApi = (d) => { 
  //   console.log('show d',d);
  // }
  const downloadWorkGroupData = async () => {
      
    setShowProgress(true);
    setPercentProgress(0);
    
    let responseApiOfWorkgroup = [];
    let index = 0;
    for await (let vehicle of vehicles){ //await each vehicle fetch data.
          let req = {
                        tbname:"workreportdetail",
                        vehicleid: `${vehicle.vehicleid}`,
                        starttime:`${selectedYear}-01-01 00:00:00`,
                        endtime:`${selectedYear}-12-31 23:59:59`
                    };
          setPercentProgress(parseInt((index/vehicleTotal)*100));
          const response = await reportService.getReportDetail(req);
          responseApiOfWorkgroup.push(response.data.data);
          index++;
    }//end for vehicle

    setShowProgress(false);
    return responseApiOfWorkgroup;
  };

  const ProgressLoading = () => (
    <Flex wrap gap="middle" style={{ marginTop: 16 }} justify="center" >
      <Progress
        type="dashboard"
        steps={vehicleTotal}
        percent={percentProgress}
        trailColor="rgba(0, 0, 0, 0.06)"
        strokeWidth={20}
      />
    </Flex>
  )

  // The following colors will be used sequentially for the chart bars
  return (
    <div style={{ marginTop: 12 }}>
          <Flex gap='middle' justify='center' align='center'>
              <Select
                showSearch={true}
                style={{ width: '200px', marginRight: 10 }}
                placeholder="Select workgroup"
                onChange={(value,e) => {
                  loadSelectedData(e.key,e.value);
                }}
              >
                {selectWorkGroupName?.map(x => (
                  <Option key={x.workgroupid} value={x.workgroupname}>
                    {x.workgroupname}
                  </Option>
                ))}
              </Select>

              <Badge count={vehicleTotal} color="cyan">
                <Button type="text" disabled badge={{ count: 12 }} icon={<UsergroupAddOutlined />}>
                  {focusWorkGroupName}
                </Button>
              </Badge>

              <Select
                showSearch={true}
                style={{ width: '200px', marginRight: 10 }}
                placeholder="Select year"
                onChange={(value) => {
                  setSelectedYear(value);
                }}
              >
                {selectionYear?.map(x => (
                  <Option key={x} value={x}>
                    {x}
                  </Option>
                ))}
              </Select>

              <Button
                type="primary" 
                onClick={handleStart}>
                Start
              </Button>
              
          </Flex>
          <div>
            { showProgress ? <ProgressLoading /> : null }
          </div>
    </div>
  );
}

export default DownloadDataOfWorkgroupPermission;