import reportInstance from './instances/reportInstance';

const reportService = {
    getReport(req) {
        return reportInstance.post('/getreport', req);
    },
    getReportDetail(req) {
        return reportInstance.post('/getreportdetail', req);
    }
}

export default reportService;