const days = [
    'D1',
    'D2',
    'D3',
    'D4',
    'D5',
    'D6',
    'D7',
    'D8',
    'D9',
    'D10',
    'D11',
    'D12',
    'D13',
    'D14',
    'D15',
    'D16',
    'D17',
    'D18',
    'D19',
    'D20',
    'D21',
    'D22',
    'D23',
    'D24',
    'D25',
    'D26',
    'D27',
    'D28',
    'D29',
    'D30',
    'D31'
]

const defaultResult = [
    {
        periods: "D1",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D2",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D3",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D4",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D5",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D6",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D7",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D8",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D9",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D10",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D11",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D12",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D13",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D14",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D15",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D16",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D17",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D18",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D19",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D20",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D21",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D22",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D23",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D24",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D25",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D26",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D27",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D28",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D29",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D30",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D31",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#52c41a'
    },
]

const defaultResultBreak = [
    {
        periods: "D1",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D2",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D3",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D4",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D5",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D6",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D7",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D8",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D9",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D10",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D11",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D12",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D13",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D14",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D15",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D16",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D17",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D18",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D19",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D20",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D21",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D22",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D23",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D24",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D25",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D26",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D27",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D28",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D29",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D30",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
    {
        periods: "D31",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b'
    },
]

const defaultResultWorking = [
    {
        periods: "D1",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D2",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D3",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D4",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D5",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D6",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D7",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D8",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D9",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D10",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D11",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D12",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D13",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D14",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D15",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D16",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D17",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D18",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D19",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D20",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D21",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D22",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D23",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D24",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D25",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D26",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D27",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D28",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D29",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D30",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
    {
        periods: "D31",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a'
    },
]

//Start with 24 hours fill
const defaultResultUndefine = [
    {
        periods: "D1",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D2",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D3",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D4",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D5",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D6",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D7",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D8",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D9",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D10",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D11",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D12",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D13",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D14",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D15",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D16",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D17",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D18",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D19",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D20",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D21",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D22",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D23",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D24",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D25",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D26",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D27",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D28",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D29",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D30",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
    {
        periods: "D31",
        hour_amt: 24,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2'
    },
]

const periodsResult = [

]

module.exports = {
    days,
    defaultResult,
    defaultResultBreak,
    defaultResultWorking,
    defaultResultUndefine,
    periodsResult
}