import React, { useState,useRef } from 'react';
import { Row, Col, Card } from 'antd';

import DownloadDataOfWorkgroupPermission from './downloadData.of.workgroup.permission'
// import YearOfWorkgroupPermission from './year.of.workgroup.permission';
// import YearOfWorkgroup from './year.of.workgroup';
import YearOfWorkgroupTab from './year.of.workgroup.tab';
import MonthOfYearVehicle from './month.of.year.vehicle';//MonthOfYearVehicle
import DayOfMonth from './day.of.month';
import HourOfDay from './hour.of.day';

const ReportTimePeriodPermission = ({inputAllowWorkGroupId}) => {
        const [objDownload, setObjectDownload] = useState(null);

        const [indexObj, setIndexObj] = useState(null);
        const [selectedVehicleId, setSelectedVehicleId] = useState(null);
        // const [selectedVehicleName, setSelectedVehicleName] = useState('Loading');

        // const [selectedWorkGroupId, setSelectedWorkGroupId] = useState(null);
        // const [selectedWorkGroupName, setSelectedWorkGroupName] = useState(null);
        // const [selectedYear, setSelectedYear] = useState(null);
        const [selectedMonth, setSelectedMonth] = useState(null);
        // const [selectedVehicleI  d, setSelectedVehicleId] = useState(null);
        // const [arrayVehicles, setArrayVehicles] = useState([]);
        // const [arrayResApiOfWorkGroups, setArrayResApiOfWorkGroups] = useState(null);

        const [selectedDayOfMonth, setSelectedDayOfMonth] = useState(null);
        // const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(null);
        // const [dataApi, setDataApi] = useState(null);

        const cardRefCharYearOfWorkgroup = useRef(null);
        const cardRefChartMonthOfYearVehicle = useRef(null);
        const cardRefChartDayOfMonth = useRef(null);
        const cardRefChartHourOfDay = useRef(null);

        
        const handleResultApiAll = (obj) => {
            setObjectDownload(obj);
            handleFocusCard(cardRefCharYearOfWorkgroup);
        }
       
        const handleChartYear = (index,obj) => {
            setIndexObj(index);
            setSelectedVehicleId(obj.vehicleid);
            handleFocusCard(cardRefChartMonthOfYearVehicle);
        }
        const handleMonthOfYear = (strMonth,hour_amt,indexOfChartMonthOfYear) => {
            // console.log('show month of year', strMonth, hour_amt, indexOfChartMonthOfYear)
            setSelectedMonth(strMonth);
            handleFocusCard(cardRefChartDayOfMonth);
        }
        const handleDayOfMonth = (strDay,hour_amt,indexOfChartDayOfMonth) => {
            try{
                setSelectedDayOfMonth(strDay);
                handleFocusCard(cardRefChartHourOfDay);
            }catch(error){
                console.log('error',error);
            }
        }

        const handleFocusCard = (ref) => {
            if (ref.current) {
                ref.current.scrollIntoView({ behavior: 'smooth', inline: "center" });
                setTimeout(() => ref.current.scrollIntoView({ behavior: 'smooth', inline: "center" }),777);
            }
        };

        return (
            <>
                <div className="App" style={{ padding: '0px' }}>
                    <Row gutter={[8, 8]} justify="center" align="middle" >
                            <Col span={24}>
                                <Card className="card-gradient" bordered={false}>
                                        {/* <h1>{selectedWorkGroupId + selectedDayOfMonth + selectedMonth+dataApi}</h1> */}
                                        <DownloadDataOfWorkgroupPermission name="DownloadData"
                                            allowWorkGroupId={inputAllowWorkGroupId}
                                            handleResultApiAll={handleResultApiAll}
                                        />
                                </Card>
                            </Col>
                            <Col span={22}>
                                <Card ref={cardRefCharYearOfWorkgroup} className='card-white' bordered={false}>
                                        <YearOfWorkgroupTab name="CharYearOfWorkgroup"
                                            objDownload={objDownload}
                                            handleChartYear={handleChartYear}
                                        />
                                </Card>
                            </Col>

                            <Col span={22}>
                                <Card ref={cardRefChartMonthOfYearVehicle} bordered={false}>
                                        <MonthOfYearVehicle name="ChartMonthOfYearVehicle"
                                            objDownload={objDownload}
                                            indexObj={indexObj}
                                            selectedVehicleId={selectedVehicleId}
                                            handleMonthOfYear={handleMonthOfYear}
                                        />
                                </Card>
                            </Col>
                            <Col span={22}>
                                <Card ref={cardRefChartDayOfMonth} bordered={false}>
                                        <DayOfMonth name="ChartDayOfMonth"
                                            objDownload={objDownload}
                                            indexObj={indexObj}
                                            selectedMonth={selectedMonth}
                                            selectedVehicleId={selectedVehicleId}
                                            handleDayOfMonth={handleDayOfMonth}
                                        />
                                </Card>
                            </Col>
                            <Col span={22}>
                                <Card ref={cardRefChartHourOfDay} bordered={false}> 
                                        <HourOfDay name="ChartHourOfDay"
                                            objDownload={objDownload}
                                            indexObj={indexObj}
                                            selectedMonth={selectedMonth}
                                            selectedDayOfMonth={selectedDayOfMonth}
                                            selectedVehicleId={selectedVehicleId}
                                            handleMonthOfYear={handleMonthOfYear}
                                        />
                                </Card>
                            </Col>
                    </Row>
                    {/* <YearOfWorkgroupPermission name="ChartYearOfWorkgroup" 
                        allowWorkGroupId={inputAllowWorkGroupId}
                        handleWorkGroupAndYear={handleWorkGroupAndYear}
                    /> */}
                    {/* <MonthOfYearVehicle name="ChartMonthOfYearVehicle"
                        dataVehicles={arrayVehicles} 
                        selectedWorkGroupId={selectedWorkGroupId}
                        selectedWorkGroupName={selectedWorkGroupName}
                        selectedWorkGroupYear={selectedYear}
                        selectedWorkGroupVehicleId={selectedVehicleId} 
                        handleMonthOfYear={handleMonthOfYear}
                    />
                    <DayOfMonth name="ChartDayOfMonth" 
                        selectedWorkGroupId={selectedWorkGroupId}
                        selectedWorkGroupName={selectedWorkGroupName}
                        selectedWorkGroupYear={selectedYear}
                        selectedWorkGroupVehicleId={selectedVehicleId} 
                        handleMonthOfYear={handleMonthOfYear}
                    />
                    <HourOfDay name="Chart24Hr"
                        selectedWorkGroupId={selectedWorkGroupId}
                        selectedWorkGroupName={selectedWorkGroupName}
                        selectedWorkGroupYear={selectedYear}
                        selectedWorkGroupVehicleId={selectedVehicleId} 
                        handleMonthOfYear={handleMonthOfYear}
                    /> */}

                </div>
            </>
        )
}

export default ReportTimePeriodPermission;
