import React, { useState, useEffect } from 'react';
import { Button, Select } from 'antd';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import GetStatusType from "../utils/work.status";
import constantsYearLoad from '../constants/month.period.year.load';

import dayjs from 'dayjs'
import 'dayjs/locale/th';
dayjs.locale("th");

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const { Option } = Select;
// const { Search } = Input;

const MonthOfYearOfWorkgroup = ({     objDownload
                                      ,indexObj
                                      ,selectedVehicleId
                                      ,handleMonthOfYear
}) => {
  const [showWorkGroupName, setShowWorkGroupName] = useState('Loading');
  
  const [vehicles, setVehicles] = useState([]);
  const [selectVehicleIdOption, setSelectVehicleIdOption] = useState(null);
  
  const [dataChartWorking, setDataChartWorking] = useState([]);
  const [dataChartBreak, setDataChartBreak] = useState([]);

  useEffect(() => {
                    const downloadWorkGroupData = async (year,resApi,indexObj,vehicleId) => {
                                
                          let objArray = await getData(year,resApi[indexObj]);
                          
                          let arrayVehicleHourWorking = [];
                          let arrayVehicleHourBreak = [];
                          objArray.forEach( obj  => {
                            // console.log('show objArray ', obj);
                            if(obj.status_type === 'ชั่วโมงการทำงาน'){
                              arrayVehicleHourWorking.push(obj.hour_amt);
                            }else if(obj.status_type === 'ชั่วโมงพักงาน'){
                              arrayVehicleHourBreak.push(obj.hour_amt);
                            }
                          });

                          setDataChartWorking(arrayVehicleHourWorking);
                          setDataChartBreak(arrayVehicleHourBreak);

                          return;
                        // setShowProgress(false);
                    };

                    const getData = async (ofYear,resVehicle) => {
                        try {
                          
                          const objArray = calculateHours2(ofYear,resVehicle);
                          
                          // Clear format to display chart
                          objArray.forEach((obj) => {
                            obj.hour_amt = parseFloat(obj.hour_amt).toFixed(2);
                            obj.defaultValue = false;
                            obj.vehicleId = resVehicle.vehicleid;
                          });
                          return objArray;
                        } catch (err) {
                          console.error(err); // Use console.error for more prominent error logging
                          throw err; // Re-throw the error to propagate it to the caller
                        }
                    }
                  
                    const calculateHours2 = (ofYear,data) => {
                        let allPeriodData = [];
                  
                        if (data?.length > 0) {
                          allPeriodData = calculateEachRow2(ofYear,data);
                          return allPeriodData;
                        } else {
                          return allPeriodData;
                        }
                    } 
                  
                    const calculateEachRow2 = (ofYear,d) => {
                          let arrayPeriodDataWorking = [];
                          let arrayPeriodDataBreak = [];
                          let arrayPeriodDataUndefine = [];
                          
                          arrayPeriodDataWorking = structuredClone(constantsYearLoad.defaultResultWorking);
                          arrayPeriodDataBreak = structuredClone(constantsYearLoad.defaultResultBreak);
                          arrayPeriodDataUndefine = structuredClone(constantsYearLoad.defaultResultUndefine);
                  
                          initHourUndefine(ofYear,arrayPeriodDataUndefine,arrayPeriodDataWorking,arrayPeriodDataBreak);
                      
                            d?.forEach(row => {
                                
                                let strStartTime = '';
                                strStartTime = row.sttime;
                                let strEndTime = '';
                                strEndTime = row.etime;
                                // console.log('show strStartTime:', strStartTime);
                                // console.log('show strEndTime:', strEndTime);
                                let dayjsStart = dayjs(strStartTime);
                                let dayjsEnd = dayjs(strEndTime);
                                let dayjsStartingPoint = dayjs(strStartTime.slice(0,13)+':00'); //Move to point start hour.
                                let dayjsEndingPoint = dayjs(strEndTime.slice(0,13)+':00').add(1,'hour'); //Move to point end hour.
                                
                                // console.log('show dayjsStart:', dayjsStart.format('YYYY-MM-DD HH:mm'));
                                // console.log('show dayjsEnd:', dayjsEnd.format('YYYY-MM-DD HH:mm'));
                                // console.log('show time total==',dayjsStart.diff(dayjsEnd,'minute') * -1 );
                                // console.log('show dayjsStartingPoint:', dayjsStartingPoint.format('YYYY-MM-DD HH:mm'));
                                // console.log('show dayjsEndingPoint:', dayjsEndingPoint.format('YYYY-MM-DD HH:mm'));
                      
                                const sizeOfRange = dayjsEndingPoint.diff(dayjsStartingPoint,'minute');
                              
                                const qtySlot = sizeOfRange/60; //each slot is 60 minutes.
                                // console.log('show qtySlot',qtySlot);
                                
                                let minuteDiff = 0;
                                for(let i = 0; i < qtySlot; i++ ){
                                    if(i === 0){ //First slot
                                      if(i !== qtySlot-1){
                                        // console.log('Ranges of[', dayjsStart.format('YYYY-MM-DD HH:mm'),' @ ', dayjsStartingPoint.add(1,'hour').format('YYYY-MM-DD HH:mm') ,'@',i);
                                        minuteDiff = dayjsStart.diff(dayjsStartingPoint.add(1,'hour'),'minute') * -1; //Multiply -1 for convert to time used.
                                      }else{
                                        // console.log('Ranges of[', dayjsStart.format('YYYY-MM-DD HH:mm'),' @ ', dayjsEnd.format('YYYY-MM-DD HH:mm') ,'@',i);
                                        minuteDiff = dayjsStart.diff(dayjsEnd,'minute') * -1 ;
                                      }
                                    }else if(i === qtySlot-1){ //Last slot
                                      // console.log('Ranges of[', dayjsEndingPoint.add(-60,'minute').format('YYYY-MM-DD HH:mm'),' @ ', dayjsEnd.format('YYYY-MM-DD HH:mm') ,'@',i);
                                      minuteDiff = dayjsEnd.diff(dayjsEndingPoint.add(-60,'minute'),'minute'); //Multiply -1 for convert to time used.
                                      // console.log('show at last dayjsEnd', dayjsEnd.format('YYYY-MM-DD HH:mm') );
                                      // console.log('show at last dayjsEndingPoint', dayjsEndingPoint.format('YYYY-MM-DD HH:mm') );
                                    }else{ //full hour slot
                                      // console.log('Ranges of[', dayjsStartingPoint.add(60*i,'minute').format('YYYY-MM-DD HH:mm'),' @ ', dayjsStartingPoint.add((60*i)+60,'minute').format('YYYY-MM-DD HH:mm') ,'@',i);
                                      minuteDiff = 60;
                                    }
                                    // console.log('show Slot loop ',dayjsStart.format('HH:mm'),dayjsStart.add(minuteDiff,'minute').format('HH:mm'),' @', i);
                                    // console.log('show minuteDiff',minuteDiff);
                                    let strDatetimeOfPointHour = dayjsStart.add(i,'hour').startOf('hour').format('YYYY-MM-DD HH:00');
                                    let iPointHour = dayjsStart.hour() + i;
                                    pushDataIntoSlotHours(ofYear,strDatetimeOfPointHour,iPointHour,row, i,minuteDiff,arrayPeriodDataWorking,arrayPeriodDataBreak,arrayPeriodDataUndefine);
                                }
                      
                            });
                  
                            // console.log('show view array after working::', arrayPeriodDataWorking);
                            // console.log('show view array after undefine::', arrayPeriodDataUndefine);
                            // console.log('show view array after break::', arrayPeriodDataBreak);
                  
                            // return arrayPeriodDataWorking.concat(arrayPeriodDataUndefine,arrayPeriodDataBreak); //marge array
                            return arrayPeriodDataWorking.concat(arrayPeriodDataBreak); //marge array
                    };
                    
                    const pushDataIntoSlotHours = (ofYear,strDatetimeOfPointHour,iPointHour,row, indexSlot,minute_amt,arrayPeriodDataWorking,arrayPeriodDataBreak,arrayPeriodDataUndefine) => {
                          //Validate data before operation.    
                          if( ofYear !== dayjs(strDatetimeOfPointHour).year() ){
                              return;
                          }
                          /*
                          //Concept push by date
                          //loop arrayPeriodDataWorking
                          //loop arrayPeriodDataBreak
                          //loop arrayPeriodDataUndefine
                          */
                          let iMonthOfYear = dayjs(strDatetimeOfPointHour).month();
                            if(GetStatusType(row.statusname) === 'ชั่วโมงการทำงาน'){
                                if(!arrayPeriodDataWorking || !arrayPeriodDataUndefine){ //validate array before for undefined error.
                                  return;
                                }
                                //Use index for push data hour_amt
                                arrayPeriodDataWorking[iMonthOfYear].hour_amt = arrayPeriodDataWorking[iMonthOfYear].hour_amt + (minute_amt/60.00); //;
                                //and remain slot of undefined in same time.
                                arrayPeriodDataUndefine[iMonthOfYear].hour_amt = arrayPeriodDataUndefine[iMonthOfYear].hour_amt - (minute_amt/60.00); //;
                              
                            }else if(GetStatusType(row.statusname) === 'ชั่วโมงพักงาน'){
                                if(!arrayPeriodDataBreak || !arrayPeriodDataUndefine){
                                  return;
                                }
                                //Use index for push data hour_amt
                                arrayPeriodDataBreak[iMonthOfYear].hour_amt = arrayPeriodDataBreak[iMonthOfYear].hour_amt + (minute_amt/60.00); //;
                                //and remain slot of undefined in same time.
                                arrayPeriodDataUndefine[iMonthOfYear].hour_amt = arrayPeriodDataUndefine[iMonthOfYear].hour_amt - (minute_amt/60.00); //;
                            }
                            else{ //Undifined
                                if(!arrayPeriodDataUndefine){
                                  return;
                                }
                            } 
                    };
                  
                    const initHourUndefine = (ofYear,arrayPeriodDataUndefine,arrayPeriodDataWorking,arrayPeriodDataBreak) => {
                      arrayPeriodDataUndefine.forEach( e => {
                          e.hour_amt = 800;
                          e.year = ofYear;
                          e.vehicleId = null;
                      });
                      arrayPeriodDataWorking.forEach( e => {
                        e.hour_amt = 0;
                        e.year = ofYear;
                        e.vehicleId = null;
                      });
                      arrayPeriodDataBreak.forEach( e => {
                        e.hour_amt = 0;
                        e.year = ofYear;
                        e.vehicleId = null;
                      });
                    };
                    
            if(!!objDownload && (!!indexObj || indexObj===0) && (!!selectedVehicleId) ){
               try{
                    setShowWorkGroupName(objDownload.vehiclesArray[indexObj].vehiclename+'@'+objDownload.year);
                    setVehicles(objDownload.vehiclesArray);
                    // let listVehicleLicense = [];
                    // objDownload.vehiclesArray.forEach( row =>{
                    //   listVehicleLicense.push(row.vehiclelicence);
                    // });
                    // setArrayVehicleName(listVehicleLicense);
                    downloadWorkGroupData(objDownload.year,objDownload.responseApiOfWorkgroup,indexObj,selectedVehicleId);
               }catch(error){
                console.error("Error log: ", error);
               }
            }
  }, [objDownload,indexObj,selectedVehicleId]);

  const handleItemClicked = (label,value,index) => {

    // console.log('show vechicle array',vehicles[index]);
    // console.log('show input array',label,':',value,':',index);
    handleMonthOfYear(label,value,index);
  }
  
  const ListVehicle = () => (
      <div style={{ marginTop: '1%', height: 50 }}>
          <Select
            showSearch={true}
            style={{ width: '200px', marginRight: 10 }}
            placeholder="Select vehicle"
            value={selectVehicleIdOption}
            onChange={(key,value) => {
              console.log('show key and value',key, value);
              setSelectVehicleIdOption(key);
            }}
          >
            {vehicles?.map(x => (
              <Option key={x.vehicleid} value={x.vehicleid}>
                {x.vehiclename}
              </Option>
            ))}
          </Select>
          <Button
            type="primary" 
            onClick={(event) => {
                console.log('show selectVehicleIdOption',selectVehicleIdOption);
            }}
          >
            View
          </Button>
    </div>
  )

  // The following colors will be used sequentially for the chart bars
  const options = {
    plugins: {
      title: {
          position: 'top',
          align: 'center',
          display: true,
          text: 'กราฟอัตราชั่วโมงการทำงานรายเดือนของรถ: '+showWorkGroupName,//+selectedWorkGroupName,
          padding: {
              top: 1,
              bottom: 0
          },
          font: {
            size: 20
          }
      },
      legend: {
        display: true,
        // labels: {
        //   color: 'rgb(255, 255, 132)'
        // }
      },
      tooltip: {
        callbacks: {
          label: function (data) {
            return data.formattedValue + ' Hour';
          },
        },
      },
      datalabels: {
        formatter: function (value) {
          if(parseInt(value) === 0){
            return '';
          }
          return parseFloat(value).toFixed(0);
        },
        color: "white",
        font: {
          weight: 'bold',
          size: 12,
          family: 'poppins'
        },
      }
    },
    scales: {
      x: {
          stacked: true,
          grid: {
            offset: true,
          },
          // title: {
          //   color: 'red',
          //   display: true,
          //   text: 'Day'
          // }
      },
      y: {
          stacked: true,
          // min: 0,
          // max: 20,
          title: {
            color: 'Blue',
            display: true,
            text: 'Hour'
          },
          ticks: {
            // stepSize: 5,
            callback: function(value, index, ticks) {
              return value;
            }
          }
      }
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const { datasetIndex, index } = elements[0];
        const dataset = data.datasets[datasetIndex];
        const value = dataset.data[index];
        const label = data.labels[index];
        // console.log(`Label: ${label}, Value: ${value}, Index: ${index}`);
        handleItemClicked(label,value,index);
      }
    }
  };
  const data = {
    labels: constantsYearLoad.months,
    datasets: [
      {
        label: 'ชั่วโมงการทำงาน',
        data: dataChartWorking,
        backgroundColor: 'green',
        borderColor: 'black', 
        borderWidth: 1,
        hoverBorderColor: 'grey',
        hoverBorderWidth: 2.5
      },
      {
        label: 'ชั่วโมงหยุดพัก',
        data: dataChartBreak,
        backgroundColor: 'red',
        borderColor: 'black', 
        borderWidth: 1,
        hoverBorderColor: 'grey',
        hoverBorderWidth: 2.5
      },
    ],
  };

  return (
    <div>
          
      <div
        style = {
          { padding: '15px',marginRight: '40px'}
        } 
      >
        <Bar
          data={data} 
          options={options} >
        </Bar>
        <div>
              { false ? <ListVehicle /> : null }
              {/* { !!vehicles ? <ListVehicle /> : null } */}
        </div>
      </div>
      
    </div>
  );
}

export default MonthOfYearOfWorkgroup;