const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

const defaultResultWorking = [
    {
        periods: "January",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a',
        year: null,
        vehicleId: null
    },
    {
        periods: "February",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a',
        year: null,
        vehicleId: null
    },
    {
        periods: "March",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a',
        year: null,
        vehicleId: null
    },
    {
        periods: "April",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a',
        year: null,
        vehicleId: null
    },
    {   
        periods: "May",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a',
        year: null,
        vehicleId: null
    },
    {
        periods: "June",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a',
        year: null,
        vehicleId: null
    },
    {
        periods: "July",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a',
        year: null,
        vehicleId: null
    },
    {
        periods: "August",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a',
        year: null,
        vehicleId: null
    },
    {
        periods: "September",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a',
        year: null,
        vehicleId: null
    },
    {
        periods: "October",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a',
        year: null,
        vehicleId: null
    },
    {
        periods: "November",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a',
        year: null,
        vehicleId: null
    },
    {
        periods: "December",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: true,
        color: '#52c41a',
        year: null,
        vehicleId: null
    },
]

const defaultResultBreak = [
    {
        periods: "January",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b',
        year: null,
        vehicleId: null
    },
    {
        periods: "February",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b',
        year: null,
        vehicleId: null
    },
    {
        periods: "March",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b',
        year: null,
        vehicleId: null
    },
    {
        periods: "April",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b',
        year: null,
        vehicleId: null
    },
    {   
        periods: "May",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b',
        year: null,
        vehicleId: null
    },
    {
        periods: "June",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b',
        year: null,
        vehicleId: null
    },
    {
        periods: "July",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b',
        year: null,
        vehicleId: null
    },
    {
        periods: "August",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b',
        year: null,
        vehicleId: null
    },
    {
        periods: "September",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b',
        year: null,
        vehicleId: null
    },
    {
        periods: "October",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b',
        year: null,
        vehicleId: null
    },
    {
        periods: "November",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b',
        year: null,
        vehicleId: null
    },
    {
        periods: "December",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: true,
        color: '#f73b3b',
        year: null,
        vehicleId: null
    },
]

const defaultResultUndefine = [
    {
        periods: "January",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2',
        year: null,
        vehicleId: null
    },
    {
        periods: "February",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2',
        year: null,
        vehicleId: null
    },
    {
        periods: "March",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2',
        year: null,
        vehicleId: null
    },
    {
        periods: "April",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2',
        year: null,
        vehicleId: null
    },
    {   
        periods: "May",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2',
        year: null,
        vehicleId: null
    },
    {
        periods: "June",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2',
        year: null,
        vehicleId: null
    },
    {
        periods: "July",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2',
        year: null,
        vehicleId: null
    },
    {
        periods: "August",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2',
        year: null,
        vehicleId: null
    },
    {
        periods: "September",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2',
        year: null,
        vehicleId: null
    },
    {
        periods: "October",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2',
        year: null,
        vehicleId: null
    },
    {
        periods: "November",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2',
        year: null,
        vehicleId: null
    },
    {
        periods: "December",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: true,
        color: '#a5afc2',
        year: null,
        vehicleId: null
    },
]

const periodsResult = [
    {
        periods: "January",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: false
    },
    {
        periods: "February",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: false
    },
    {
        periods: "March",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: false
    },
    {
        periods: "April",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: false
    },
    {   
        periods: "May",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: false
    },
    {
        periods: "June",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: false
    },
    {
        periods: "July",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: false
    },
    {
        periods: "August",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: false
    },
    {
        periods: "September",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: false
    },
    {
        periods: "October",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: false
    },
    {
        periods: "November",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: false
    },
    {
        periods: "December",
        hour_amt: 0,
        status_code: ["WW", "WR"],
        status_type: "ชั่วโมงการทำงาน",
        defaultValue: false
    },
    {
        periods: "January",
        hour_amt: 0,
        status_code: ["RW", "RR"],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: false
    },
    {
        periods: "February",
        hour_amt: 0,
        status_code: ["RW", "RR"],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: false
    },
    {
        periods: "March",
        hour_amt: 0,
        status_code: ["RW", "RR"],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: false
    },
    {
        periods: "April",
        hour_amt: 0,
        status_code: ["RW", "RR"],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: false
    },
    {   
        periods: "May",
        hour_amt: 0,
        status_code: ["RW", "RR"],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: false
    },
    {
        periods: "June",
        hour_amt: 0,
        status_code: ["RW", "RR"],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: false
    },
    {
        periods: "July",
        hour_amt: 0,
        status_code: ["RW", "RR"],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: false
    },
    {
        periods: "August",
        hour_amt: 0,
        status_code: ["RW", "RR"],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: false
    },
    {
        periods: "September",
        hour_amt: 0,
        status_code: ["RW", "RR"],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: false
    },
    {
        periods: "October",
        hour_amt: 0,
        status_code: ["RW", "RR"],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: false
    },
    {
        periods: "November",
        hour_amt: 0,
        status_code: ["RW", "RR"],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: false
    },
    {
        periods: "December",
        hour_amt: 0,
        status_code: ["RW", "RR"],
        status_type: "ชั่วโมงพักงาน",
        defaultValue: false
    },
    {
        periods: "January",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: false
    },
    {
        periods: "February",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: false
    },
    {
        periods: "March",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: false
    },
    {
        periods: "April",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: false
    },
    {   
        periods: "May",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: false
    },
    {
        periods: "June",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: false
    },
    {
        periods: "July",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: false
    },
    {
        periods: "August",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: false
    },
    {
        periods: "September",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: false
    },
    {
        periods: "October",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: false
    },
    {
        periods: "November",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: false
    },
    {
        periods: "December",
        hour_amt: 0,
        status_code: ["", ""],
        status_type: "ชั่วโมงอับสัญญาณ",
        defaultValue: false
    },
]

module.exports = {
    months,
    defaultResultWorking,
    defaultResultBreak,
    defaultResultUndefine,
    periodsResult
}