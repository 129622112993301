import reportInstance from './instances/reportInstance';
import axios from "axios";

var apiUrl = process.env.REACT_APP_SMART_TRACK_API;

const permissionService = {
    getGroupIdPermission(req) {
        return reportInstance.post('/getGroupIdPermission', req);
    },
    getWorkGroupReportFromSmartTrackApi(token) {
        // const apiUrl = 'https://smarttrack-qas.fairfast.co.th/wh/getworkgroup';
        //const apiUrl = 'http://smarttrack-qas.fairfast.co.th/wh/getworkgroup'; 
        // apiUrl = 'https://smarttrack-qas.fairfast.co.th/wh/getworkgroup';

        try{
            return axios.post(apiUrl, {} , { 
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
             });
        }catch(error){
            console.error('Error!! getWorkGroupReportFromSmartTrackApi', error);
            if (error.response) {
                // Server responded with a status other than 200 range
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
                // Request was made but no response was received
                console.error('Request data:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            let resError = { data:{ code: '099', message: error.message}};
            console.log(resError);
            return resError;
        }
        
    }
}

export default permissionService;