import axios from "axios";

const apiUrl = process.env.REACT_APP_REPORT_API_ENDPOINT;
const appAuthUser = process.env.REACT_APP_AUTH_USERNAME;
const appAuthPass = process.env.REACT_APP_AUTH_PASSWORD;
const apiKeyUser = process.env.REACT_APP_API_KEY_USERNAME;
const apiKeyPass = process.env.REACT_APP_API_KEY_PASSWORD;

const instance = axios.create({
  baseURL: apiUrl,
  timeout: 60000,
  apiKey: apiKeyUser,
  apiKeyPass: apiKeyPass,
  apiAuthUser: appAuthUser,
  apiAuthPass: appAuthPass
});

var tokenKey = '';

instance.interceptors.request.use(
  async (config) => {
    if(tokenKey === ''){
      let res = await fetchToken();
      // console.log('show token',res.data.data.token);
      tokenKey = res.data.data.token;
    }
    if (tokenKey !== '' || tokenKey) {
      config.headers.Authorization = `Bearer ${tokenKey}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

async function fetchToken() {
  
    const data = {
       username: appAuthUser,
       password: appAuthPass
    };
    const auth = {
      username : apiKeyUser, 
      password : apiKeyPass
    }
    return axios.post( apiUrl+'/getlogin', data, {auth});
}

export default instance;